import { Controller } from '@hotwired/stimulus'
import { toAmount, toCents } from 'common/filters/money'
import { emit } from '../beam/utils/event'

export default class extends Controller {
  static values = {
    duration: Number,
    activeNonTrialMembersCount: Number,
    currentMrr: Number,
    currency: String,
    activeNonTrialSubscriptionsPerPriceCount: Object,
    activeTrialSubscriptionsPerPriceCount: Object
  }

  static targets = [
    'newPriceField',
    'applyButton',
    'mrrPreview',
    'emailFields',
    'affectedUsers',
    'decreasingPriceNotice',
    'changingPrice'
  ]

  increasingPriceCount = 0

  decreasingPriceCount = 0

  changingPriceCount = 0

  applyTo = 'new_members'

  connect() {
    this.originalPriceField = document.getElementById(`${this.currencyValue}-currency-input`)
    this.originalPrice = Number(this.originalPriceField.value)
    this.newPrice = Number(this.newPriceFieldTarget.value)
    this.prices = Array
      .from(
        new Set(Object.keys(this.activeNonTrialSubscriptionsPerPriceCountValue)
          .concat(Object.keys(this.activeTrialSubscriptionsPerPriceCountValue)))
      )
      .map(price => Number(price))

    this.calculateChangingPriceCount()
    this.updateMrrDetails()
    this.updateAffectedUsersCopy()
    this.updateApplyButton()
  }

  closeDrawer() {
    this.element.closest('ds-modal').close()
  }

  handlePriceChange() {
    this.newPrice = Number(this.newPriceFieldTarget.value)

    this.calculateChangingPriceCount()
    this.updateMrrDetails()
    this.updateEmailFields()
    this.updateDecreasingPriceNotice()
    this.updateApplyButton()
    this.updateAffectedUsersCopy()
  }

  handleApplyTo(event) {
    this.applyTo = event.target.value
    this.updateEmailFields()
    this.updateDecreasingPriceNotice()
    this.updateApplyButton()
  }

  updateMrrDetails() {
    const durationInMonths = Math.round(this.durationValue / 30.0)
    const projectedMrr = (this.activeNonTrialMembersCountValue * this.newPrice) / durationInMonths
    const mrrDiff = Math.abs(projectedMrr - this.currentMrrValue)
    const sign = projectedMrr > this.currentMrrValue ? '+' : '-'

    if (mrrDiff > 0) {
      const formattedDiff = toAmount(toCents(mrrDiff, this.currencyValue), false, undefined, this.currencyValue)

      this.mrrPreviewTarget.textContent = `${sign}${formattedDiff} ${this.currencyValue}/month`
      this.mrrPreviewTarget.classList.remove('hidden')
    } else {
      this.mrrPreviewTarget.classList.add('hidden')
    }
  }

  updateEmailFields() {
    const showEmailFields = this.applyTo === 'all_members' && this.increasingPriceCount > 0
    this.emailFieldsTarget.classList.toggle('hidden', !showEmailFields)
  }

  updateDecreasingPriceNotice() {
    if (this.decreasingPriceCount > 0 && this.increasingPriceCount === 0 && this.applyTo === 'all_members') {
      this.decreasingPriceNoticeTarget.classList.remove('hidden')
    } else {
      this.decreasingPriceNoticeTarget.classList.add('hidden')
    }
  }

  // We should cover the following scenarios:
  // * New members: any changes to the price.
  // * Existing members: when the amount has changed or when there are grandfathered subscriptions.
  isApplyingNewPrice() {
    const hasAmountChanged = this.newPrice !== this.originalPrice

    if (this.applyTo === 'new_members') {
      return hasAmountChanged
    }

    return hasAmountChanged || this.changingPriceCount > 0
  }

  updateApplyButton() {
    this.applyButtonTarget.disabled = !this.isApplyingNewPrice()
  }

  updateAffectedUsersCopy() {
    this.affectedUsersTarget.textContent = this.changingPriceCount === 1
      ? `${this.changingPriceCount} existing user will be affected.`
      : `${this.changingPriceCount} existing users will be affected.`
  }

  apply() {
    this.changingPriceTarget.value = this.isApplyingNewPrice()
    this.originalPriceField.value = this.newPrice
    this.originalPriceField.dataset.applyTo = this.applyTo
    this.originalPriceField.dataset.increasingPriceCount = this.increasingPriceCount
    this.originalPriceField.dataset.changingPriceCount = this.changingPriceCount
    emit(this.originalPriceField, 'change')

    this.closeDrawer()
  }

  calculateChangingPriceCount() {
    const affectedPrices = this.prices.filter(price => price !== toCents(this.newPrice, this.currencyValue))

    const lowerPrices = affectedPrices.filter(price => price < toCents(this.newPrice, this.currencyValue))
    const higherPrices = affectedPrices.filter(price => price > toCents(this.newPrice, this.currencyValue))

    this.increasingPriceCount = lowerPrices.reduce((partialSum, lowerPrice) => {
      let affectedNonTrialsCount = 0

      if (this.activeNonTrialSubscriptionsPerPriceCountValue[lowerPrice]) {
        affectedNonTrialsCount = this.activeNonTrialSubscriptionsPerPriceCountValue[lowerPrice]
      }
      return partialSum + affectedNonTrialsCount
    }, 0)

    this.decreasingPriceCount = higherPrices.reduce((partialSum, higherPrice) => {
      let affectedNonTrialsCount = 0
      let affectedTrialsCount = 0

      if (this.activeNonTrialSubscriptionsPerPriceCountValue[higherPrice]) {
        affectedNonTrialsCount = this.activeNonTrialSubscriptionsPerPriceCountValue[higherPrice]
      }

      if (this.activeTrialSubscriptionsPerPriceCountValue[higherPrice]) {
        affectedTrialsCount = this.activeTrialSubscriptionsPerPriceCountValue[higherPrice]
      }
      return partialSum + affectedNonTrialsCount + affectedTrialsCount
    }, 0)

    this.changingPriceCount = this.increasingPriceCount + this.decreasingPriceCount
  }
}
