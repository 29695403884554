import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static targets = ['list', 'uploader']

  static values = { createUrl: String }

  connect() {
    this.uploadedSuccess = this.uploadedSuccess.bind(this)
    this.uploaderTarget.addEventListener('success', this.uploadedSuccess)
  }

  uploadedSuccess(e) {
    const forms = e.detail.map(x => ({ name: x.name, public_url: x.uploadURL }))

    forms.forEach(form => {
      new FetchRequest('post', this.createUrlValue, {
        body: JSON.stringify({
          form
        })
      }).perform()
    })
  }

  something(el) {
    new FetchRequest('delete', el.currentTarget.dataset.url).perform()
  }

  showEditModal(e) {
    const { id, editUrl } = e.currentTarget.dataset

    this.showController.createSubtitleModal(id, editUrl)
  }

  destroy(e) {
    // eslint-disable-next-line no-restricted-globals
    e.currentTarget.disabled = true
    const { deleteUrl, subtitleId } = e.currentTarget.dataset
    new FetchRequest('delete', deleteUrl).perform()
    document.getElementById(`delete-subtitle-modal-${subtitleId}`).close()
  }

  get showController() {
    const container = this.element.closest('div[data-controller="contents--video-edit--show"]')
    return this.application.getControllerForElementAndIdentifier(container, 'contents--video-edit--show')
  }
}
