import { Controller } from '@hotwired/stimulus'
import { debounce, every, some } from 'lodash'
import dayjs from 'dayjs'

export default class extends Controller {
  static targets = [
    'form',
    'itemsSearching',
    'selectAll',
    'dateChoosingInput',
    'hiddenInputsBlock',
    'searchInput'
  ]

  static values = { hideScheduled: Boolean, baseUrl: String }

  searchContent = debounce(() => {
    this.fetchContentResults()
  }, 500)

  preserveFormSubmission(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  get storeTimezone() {
    return this.dateChoosingInputTarget.getAttribute('timezone')
  }

  get chosenDate() {
    if (!this.dateChoosingInputTarget.value) return ''

    return dayjs(this.dateChoosingInputTarget.value * 1000).tz(this.storeTimezone).format('YYYY-MM-DD')
  }

  get selectedVideosIDs() {
    const selected = []
    this.hiddenInputsBlockTarget.querySelectorAll('[name*="_destroy"][value="0"]').forEach(el => {
      const index = el.name.replace(/\D/g, '')
      selected.push(
        this.hiddenInputsBlockTarget.querySelector(
          `[name="content_calendar[items_attributes][${index.toString()}][content_id]"]`,
        ).value,
      )
    })

    return selected
  }

  resolveSelectAll() {
    if (!this.hasSelectAllTarget) return

    const isAllSelected = every(this.allVideoCheckboxes, el => el.checked)
    const isAnySelected = some(this.allVideoCheckboxes, el => el.checked)

    if (isAllSelected) {
      this.selectAllTarget.removeAttribute('indeterminate', false)
      this.selectAllTarget.checked = true
    } else if (isAnySelected) {
      this.selectAllTarget.setAttribute('indeterminate', true)
      this.selectAllTarget.checked = true
    } else {
      this.selectAllTarget.removeAttribute('indeterminate', false)
      this.selectAllTarget.checked = false
    }
  }

  get allVideoCheckboxes() {
    return this.itemsSearchingTarget.querySelectorAll('[id*="video_id_"]')
  }

  connect() {
    // Calculate content results per page
    const resultsBlock = this.itemsSearchingTarget.firstElementChild
    const itemsCanBeShowedSize = Math.floor(resultsBlock.offsetHeight / 55) // 55 is a height of search item
    const perCount = itemsCanBeShowedSize - 3 // 3 items heights for `select all` checkbox and paddings

    this.perParam = perCount <= 0 ? 10 : perCount

    // Initialize first page of content results
    this.fetchContentResults()

    // Select checkboxes after frame loading
    this.itemsSearchingTarget.addEventListener('turbo:frame-load', () => {
      this.allVideoCheckboxes.forEach(el => {
        if (this.selectedVideosIDs.includes(el.getAttribute('value'))) { el.setAttribute('checked', 'true') }
      })

      this.resolveSelectAll()
    })

    // Form validation, date is required
    this.formTarget.addEventListener('turbo:submit-start', event => {
      if (this.chosenDate === '') {
        this.dateChoosingInputTarget.setAttribute('error', 'Specify the date, please')
        event.detail.formSubmission.stop()
      }
    })

    // Disbaled dates setting (repeating front-end solution from live event details form)
    this.dateChoosingInputTarget.setAttribute('disabled-dates', `0 - ${Date.now() - 86400}`)

    // Behavior after success form submit
    this.formTarget.addEventListener('turbo:submit-end', event => {
      if (event.detail.success) {
        this.formTarget.closest('ds-modal').close()
        document.getElementById('calendar').src = `/bullet/contents/calendar?date=${this.chosenDate}`
      }
    })

    // Selecting checkboxes
    this.element.addEventListener('change', event => {
      if (event.target.id && event.target.id.includes('video_id_')) {
        const inputsBlock = this.hiddenInputsBlockTarget

        if (event.target.checked) {
          const isElementInTree = inputsBlock.querySelector(
            `[name*="[content_id]"][value="${event.target.getAttribute('value')}"]`,
          )

          if (isElementInTree) {
            const index = isElementInTree.name.replace(/\D/g, '')
            inputsBlock.querySelector(`[name*="[${index.toString()}][_destroy]"]`).setAttribute('value', '0')
          } else {
            const uniqId = Math.floor(Math.random() * 10000000).toString()
            const contentInput = document.createElement('input')
            contentInput.setAttribute('type', 'hidden')
            contentInput.setAttribute('name', `content_calendar[items_attributes][${uniqId}][content_id]`)
            contentInput.setAttribute('value', event.target.getAttribute('value'))

            const dateInput = document.createElement('input')
            dateInput.setAttribute('type', 'hidden')
            dateInput.setAttribute('name', `content_calendar[items_attributes][${uniqId}][date]`)
            dateInput.setAttribute('value', this.chosenDate)

            const destroyInput = document.createElement('input')
            destroyInput.setAttribute('type', 'hidden')
            destroyInput.setAttribute('name', `content_calendar[items_attributes][${uniqId}][_destroy]`)
            destroyInput.setAttribute('value', '0')

            inputsBlock.appendChild(contentInput)
            inputsBlock.appendChild(dateInput)
            inputsBlock.appendChild(destroyInput)
          }
        } else {
          const isElementInTree = inputsBlock.querySelector(
            `[name*="[content_id]"][value="${event.target.getAttribute('value')}"]`,
          )

          if (isElementInTree) {
            const index = isElementInTree.name.replace(/\D/g, '')
            const isElementAlreadySaved = inputsBlock.querySelector(`[name*="[${index.toString()}][id]"]`)
            if (isElementAlreadySaved) {
              inputsBlock.querySelector(`[name*="[${index.toString()}][_destroy]"]`).setAttribute('value', '1')
            } else {
              inputsBlock
                .querySelector(`[name="content_calendar[items_attributes][${index.toString()}][content_id]"]`)
                .remove()
              inputsBlock
                .querySelector(`[name="content_calendar[items_attributes][${index.toString()}][date]"]`)
                .remove()
              inputsBlock
                .querySelector(`[name="content_calendar[items_attributes][${index.toString()}][_destroy]"]`)
                .remove()
            }
          }
        }
        this.resolveSelectAll()
      }
    })
  }

  // Select all checkbox in content results
  selectAll(event) {
    if (event.target.checked) {
      this.allVideoCheckboxes.forEach(el => {
        if (!el.checked) el.click()
      })
    } else {
      this.allVideoCheckboxes.forEach(el => {
        if (el.checked) el.click()
      })
    }
  }

  fetchContentResults() {
    const url = new URL(this.baseUrlValue)

    url.searchParams.append('sorted_first_ids', this.selectedVideosIDs.join(','))
    url.searchParams.append('per', this.perParam)
    url.searchParams.append('date', this.chosenDate)
    url.searchParams.append('q', this.searchInputTarget.value)
    url.searchParams.append('hide_scheduled', this.hideScheduledValue)

    this.itemsSearchingTarget.src = url.href
  }
}
